import React, { Fragment } from 'react';
import { MDBBtn, MDBContainer, MDBCol } from 'mdbreact';

const HomepageCTA = () => {
    return (
        <div>
            <MDBContainer className="px-0">

                <MDBCol md="12" className="px-0">
                    <h4 className="px-2 py-2 font-weight-bold text-secondary">Quick Links</h4>
                    <Fragment>
                        <MDBBtn outline color="primary" size="lg" className="w-100" href="/poojareq">Pooja request</MDBBtn>
                        <MDBBtn outline color="secondary" size="lg" className="w-100" href="/newsletter">News letter signup</MDBBtn>
                        <MDBBtn outline size="lg" className="w-100" href="/volunteersignup">Volunteer Signup</MDBBtn>
                        <MDBBtn outline color="secondary" size="lg" className="w-100" href="/ldform">Land Donation</MDBBtn>
                    </Fragment>
                </MDBCol>
            </MDBContainer>
        </div>
    );
};

export default HomepageCTA;