import React from 'react';
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact';

const PriestCal = () => {
    return (
        <>
            <MDBContainer className="px-0">

                <MDBCol md="12" className="px-1">
                    <h4 className="px-2 py-2 font-weight-bold text-secondary">Priest Calendar</h4>
                    <iframe width="100%" scrolling="yes" height="300" src="https://www.google.com/calendar/embed?showCalendars=0&amp;showTitle=0&amp;showNav=0&amp;showDate=0&amp;showPrint=0&amp;showTabs=1&amp;mode=AGENDA&amp;height=600&amp;wkst=1&amp;bgcolor=%23FFFFFF&amp;src=iowahindutemple%40gmail.com&amp;color=%23A32929&amp;ctz=America%2FChicago" style={{ borderWidth: 0 }} title="Priest Calender" frameborder="0"></iframe>
                </MDBCol>

            </MDBContainer>
        </>
    );
};

export default PriestCal;