import React from "react"
import { MDBJumbotron, MDBRow, MDBCol, MDBContainer } from "mdbreact"
import SliderImages from "./SliderImages";
import './slider.scss';
import { FirebaseContext } from '../Firebase';
class Jumbotron extends React.Component {
  static contextType = FirebaseContext;

  state = {
    load: true,
    images: null
  }
  setimageURL = (items) => {
    this.setState({ images: [...this.state.images, items] });
    //return url
  }
  getImageURL = (item) => {
    return item.getDownloadURL().then(function (url) { return url })
  }

  getlist = () => {
    const { firebase, loading } = this.context;
    if (!loading) {
      if (this.state.load) {
        firebase.db.collection("banners").where("active", "==", true).get().then(querySnapshot => {
          //console.log(querySnapshot.docs);
          const data = querySnapshot.docs.map(doc => {
            const datadoc = { id: doc.id, ...doc.data() };
            return datadoc;
          });
          debugger;
          this.setState({ load: !this.state.load, images: data });

        }).catch((e) => {
          console.log(e);
        });
      } else {

      }
    }
  }
  getimages = () => {
    const { firebase, loading } = this.context;
    if (!loading) {
      if (this.state.load) {
        let storageRef = firebase.storage.ref("banners");
        var self = this;
        const d = storageRef.listAll().then(function (data) {
          const urls = data.items;
          console.log(self.state);
          const tarray = urls.map(async (itemRef) => {
            const url = await itemRef.getDownloadURL().then(u => u)
            return url.toString();
          })
          Promise.all(tarray).then(function (data) {
            self.setState({ load: !self.state.load, images: data });
            console.log(data);

          });
        })
      }
    }
  }

  render() {
    return (
      <div

        className="py-0 px-0 w-70 mx-auto"
      >
        {this.getimages()}
        {this.state.load ? "" :
          <MDBContainer fluid className="mt-0 text-center py-0 px-0" >
            <MDBRow>
              <MDBCol className="px-0">
                <SliderImages imgData={this.state.images} />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        }
      </div>
    )
  }
}

export default Jumbotron;
