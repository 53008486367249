import React from 'react';
import './Event.scss';
import { MDBIcon, MDBBtn } from 'mdbreact';
import ToggleButton from 'react-toggle-button';
import Moment from 'react-moment';
import ClampLines from 'react-clamp-lines';
import ReactFitText from 'react-fittext';

const Event = (props) => {
    //console.log(props);
    
    return (

        <div className={`eventcard p-2 ${props.fullpage ? "col-md-12 fullpage float-left" : "col-md-6 float-left"}  `} >
            < div className="card border border-danger">
                <div className="row">
                    <div className="col-md-4 disp">
                        {/* <MDBIcon far icon="calendar-check" size="xs" /> */}
                        {props.node.image ?
                            <img src={props.node.image.value} alt={props.node.image.label} className="img-thumbnail z-depth-1  m-3 " />
                            : '<MDBIcon far icon="calendar-check" size="xs" />'
                        }
                    </div>
                    <div className="col-md-8 px-0 py-auto my-auto">
                        <div className="card-block px-3 ">
                            <ReactFitText maxFontSize={props.fullpage ? "20px" : "16px"}>
                                <h6 className="card-title font-weight-bold pt-2 mb-0">{props.node.title}</h6>
                            </ReactFitText>
                            <ReactFitText maxFontSize={props.fullpage ? "20px" : "14px"}>
                                <h6 className="mb-0 py-1">
                                    {props.special === "true" ? "" : <span> {props.node.frequency === "Daily" ? " Every day " : props.node.day} </span>} 
                                    <span className="text-primary font-weight-bolder">
                                        
                                        {props.node.starttime ? props.node.starttime : <Moment date={new Date(props.node.start.seconds * 1000).toLocaleString()} parse="MM/DD/YYYY, HH:mm:ss A"  format="D MMM hh:mm A" />} {/*props.node.endtime ? "-" + props.node.endtime : ""*/} {/*new Date(props.node.end.seconds * 1000).toLocaleString()*/}
                                        </span></h6>
                            </ReactFitText>

                            <ClampLines
                                text={props.node.description ? props.node.description : ""}
                                lines={props.fullpage ? 7 : 2}
                                id="default"
                                ellipsis='..'
                                moreText="Read more.."
                                className="clamp-lines"
                                style={{ overflowY: "scroll", maxHeight: "60px" }}
                            />


                        </div>
                    </div>
                    <div className="col-md-12 ">
                        {props.admin ? <>
                            <MDBBtn size="xs" color="red" className="float-right" id={props.node.id} onClick={props.handleDelete}>Delete</MDBBtn>
                            <MDBBtn size="xs" color="teal" className="float-right" id={props.node.id} onClick={props.handleUpdate}>Edit</MDBBtn>
                            <div className="float-right" style={{ padding: "15px 5px" }}>
                                <ToggleButton
                                    className="float-right"
                                    inactiveLabel="OFF"
                                    activeLabel="ON"
                                    value={props.node.active}
                                    onToggle={(value) => { console.log(value, "toggleitem"); props.toggleActiveState(!value, props.node.id) }} /> </div>
                        </> : ""}
                    </div>

                </div>
            </div>
        </div >

    );
};

export default Event;