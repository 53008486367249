import React from 'react';
import { MDBContainer, MDBCol } from 'mdbreact';

class Panchang extends React.Component {




    //fetch("https://www.mypanchang.com/mobilewidget.php?cityname=CedarRapids-Iowa-USA&displaymode=full&color=FFFFE0", {"credentials":"include","headers":{"accept":"text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9","accept-language":"en-US,en;q=0.9,te;q=0.8","sec-fetch-dest":"iframe","sec-fetch-mode":"navigate","sec-fetch-site":"cross-site","upgrade-insecure-requests":"1"},"referrer":"http://localhost:8000/","referrerPolicy":"no-referrer-when-downgrade","body":null,"method":"GET","mode":"cors"});
    //fetch("https://www.mypanchang.com/mobilewidget.php?cityname=CedarRapids-Iowa-USA&color=FFFFE0", {"credentials":"omit","headers":{"accept":"*/*","accept-language":"en-US,en;q=0.9,te;q=0.8","sec-fetch-dest":"empty","sec-fetch-mode":"cors","sec-fetch-site":"cross-site"},"referrer":"http://localhost:8000/","referrerPolicy":"no-referrer-when-downgrade","body":null,"method":"GET","mode":"cors"});
    render() {
        return (
            <>
                {/* {this.getData()} */}
                <MDBContainer className="px-0">

                    <MDBCol md="12" className="px-0">
                        <h4 className="px-2 py-2 font-weight-bold text-secondary">Daily Panchang</h4>
                        <iframe
                            src="https://www.mypanchang.com/mobilewidget.php?cityname=CedarRapids-Iowa-USA&displaymode=full&color=FFFFE0"
                            scrolling="yes" frameBorder="0"
                            style={{ border: "none", background: "white" }} width="88%" height="300" id="panchangframe">

                     </iframe>
                      
                    </MDBCol>

                </MDBContainer>
            </>
        );
    }
};

export default Panchang;