import React from 'react';
//import { Link } from "gatsby"
import { MDBContainer, MDBRow, MDBCol } from "mdbreact"
import UpcomingEvents from './UpcomingEvents';
import RecurringEvents from './RecurringEvents';
import { FirebaseContext } from '../Firebase';
import * as momentf from 'moment';

class Events extends React.Component {
    static contextType = FirebaseContext;
    state = {
        loade: true,
        loada: true,
        events: null,
        activities: null,
        fullpage: false
    }
    setData(data, type) {
        const datalength = data && data.length
        //debugger;
        if (!this.props.fullpage) {
            if (type === "events") {
                if (datalength > 0) {
                    this.setState({ loade: false, events: data.slice(0, 4) })
                } else {
                    this.setState({ loade: false });
                }
            } else if (type === "activities") {
                if (datalength > 0) {
                    this.setState({ loada: false, activities: data.slice(0, 4) })
                } else {
                    this.setState({ loada: false });
                }
            }
        } else {
            if (type === "events") {
                this.setState({ fullpage: true, loade: false, events: data })
            } else if (type === "activities") {
                this.setState({ fullpage: true, loada: false, activities: data })
            }
        }
        console.log(this.state);
    }

    getEventsLength() {
        let items = 0;
        if (this.state.events) {
            items = items + this.state.events.length;
        }
        if (this.state.activities) {
            items = items + this.state.activities.length;
        }
        console.log(items);
        return items;
    }


    weekdays() {
        const days = []
        const dateStart = momentf();
        const dateEnd = momentf().add(7, 'days');
        while (dateEnd.diff(dateStart, 'days') >= 0) {
            days.push(dateStart.format('dddd'))
            dateStart.add(1, 'days')
        }
        return days
    }

    testDB() {
        const { firebase, loading } = this.context;
        //console.log(loading);

        //debugger; 
        if (!loading) {
            //console.log(this.state);
            if (this.state.loade) {
                firebase.db.collection("events").where("active", "==", true).orderBy("start", 'asc').get().then(querySnapshot => {
                    //console.log(querySnapshot.docs);
                    const data = querySnapshot.docs.map(doc => {
                        const datadoc = { id: doc.id, ...doc.data() };
                        return datadoc;
                    });
                    //debugger;
                    //console.log(data);
                    this.setData(data, "events");
                    //this.setState({ loade: false, events: data.slice(0, 3) })


                }).catch((e) => {
                    console.log(e);
                });
            }
            if (this.state.loada) {
                firebase.db.collection("activities").where("active", "==", true).orderBy("frequency", 'asc').orderBy("day", 'asc').get().then(querySnapshot => {
                    //console.log(querySnapshot.docs);
                    const weeksorter = this.weekdays();

                    //debugger;
                    const data = querySnapshot.docs.map(doc => {
                        const datadoc = { id: doc.id, ...doc.data() };
                        return datadoc;
                    });
                    const dailydata = data.filter((d) => d.frequency === 'Daily');
                    console.log(dailydata);
                    const weeklydata = data.filter((d) => d.frequency === 'Weekly');
                    console.log(weeklydata);
                    weeklydata.sort(function (a, b) {
                        const A = a['day']; const B = b['day'];
                        if (weeksorter.indexOf(A) > weeksorter.indexOf(B)) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                    console.log(weeklydata);
                    this.setData([...dailydata, ...weeklydata], "activities");
                    //this.setState({ loada: false, activities: data.slice(0, 3) })

                }).catch((e) => {
                    console.log(e);
                });
            }
        }
    }



    render() {
        const eventbtn = (this.getEventsLength() > 4) ? <a href="/events" className="btn btn-default float-left  font-weight-bold">View All Events</a> : " ";
        return (


            <MDBContainer md="12" className="mx-0 mt-0" >
                {this.testDB()}
                {!this.props.fullpage ?// <h4 className="font-weight-bold py-2 " > 
                <h4 className="px-2 py-2 font-weight-bold text-secondary">Events </h4> : ""}
                <MDBRow className={this.props.fullpage ? "" : eventbtn === " " ? " " : "eventslist"}>

                    {(this.getEventsLength() > 0) ?
                        <MDBCol md="12" style={{ display: "inline-block" }} >

                            <UpcomingEvents eventdata={this.state} />

                            <RecurringEvents activitydata={this.state} />

                        </MDBCol>
                        : <h4> There are no active events </h4>}
                    {!this.props.fullpage ?
                        <MDBContainer md="12" className="mx-4 px-4 mt-0">
                            {!this.props.fullpage ? eventbtn : " "}

                        </MDBContainer> : ""
                    }

                </MDBRow>


            </MDBContainer>



        );
    }
};

export default Events;