import React from 'react';

const Announcement = (props) => {
    const linkedurl = <a href={props.item.url} className="font-weight-normal text-capitalize text-danger">Link</a>;

    return (
        <span className="font-weight-bold px-1 teal-text "  >
            {props.item.title}  {props.item.url ? linkedurl : ""}
        </span>
    );
};

export default Announcement;