import React from "react"
//import Layout from "../components/layout"
import SEO from "../components/seo"
import Jumbotron from "../components/Slider/jumbotron"
import Events from "../components/Events/Events";
import AnnouncementList from '../components/Announcements/AnnouncementList';
import PriestCal from '../components/Service/PriestCal';
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact';
import Panchang from "../components/panchang";
import HomepageCTA from '../components/homepagecta';
import Livestream from "../components/livestream";
const IndexPage = (props) => {
  //console.log("indexpage");
  //console.log(props);
  return (<>
    <SEO title="Home" />
    <MDBContainer fluid>
      <AnnouncementList />
      <MDBContainer fluid>


        <MDBRow>
          <MDBCol md="12">
            <Jumbotron />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBContainer fluid>

        <MDBRow>
          <MDBCol md="7" className="mx-0 px-0">
            <Events className="mt-4" />
          </MDBCol>
          <MDBCol md="5" className="mx-0 px-0">
            <Livestream className="mt-4" />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBContainer fluid>
        <MDBRow>
          <MDBCol md="4" className="px-0">
            <PriestCal />
          </MDBCol>
          <MDBCol md="4" className="px-3">
            <Panchang />
          </MDBCol>
          <MDBCol md="4" className="px-0">
            <HomepageCTA />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBContainer>

  </>);
}

export default IndexPage
