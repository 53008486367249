import React from "react";
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask } from "mdbreact";



const SliderImages = (props) => {
    //console.log(props.data);



    //console.log(imgData);
    return (
        <>
            {props.imgData && props.imgData.length > 0 ?
                <MDBCarousel slide interval={7000} showControls={true} activeItem={1} mobileGesture={true} length={props.imgData && props.imgData.length} className="z-depth-1 w-80">
                    <MDBCarouselInner>
                        {props.imgData.map((imgKey, index) => (
                            <MDBCarouselItem key={index} itemId={index + 1}>
                                <MDBView>
                                    <img className="d-block w-100 mx-auto" style={{ maxHeight: "340px"}} src={imgKey} alt="iowa hindu temple" />
                                    <MDBMask overlay="black-slight" />
                                </MDBView>
                            </MDBCarouselItem>
                        ))
                        }



                    </MDBCarouselInner>
                </MDBCarousel> : ""
            }
        </>
    );
}





export default SliderImages;
