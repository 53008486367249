import React from 'react';
import Announcement from './Announcement';
import { MDBContainer, MDBRow } from 'mdbreact';
import './announcement.scss';

import { FirebaseContext } from '../Firebase';
class AnnouncementList extends React.Component {
    static contextType = FirebaseContext

    state = {
        load: true,
        items: null
    }

    getlist() {
        const { firebase, loading } = this.context;
        if (!loading) {
            if (this.state.load) {
                firebase.db.collection("shoutouts").where("active", "==", true).get().then(querySnapshot => {
                    //console.log(querySnapshot.docs);
                    const data = querySnapshot.docs.map(doc => {
                        const datadoc = { id: doc.id, ...doc.data() };
                        return datadoc;
                    });
                    this.setState({ load: !this.state.load, items: data });

                }).catch((e) => {
                    console.log(e);
                });
            } else {

            }
        }
    }



    render() {
        const listItems = this.state.items && this.state.items.map((node, index) => (
            <span>
                <Announcement key={index} special="false" item={node}>
                </Announcement> {((this.state.items.length > 0) && (this.state.items.length - 1 > index)) ? <span className="secondary-text">|</span> : ""}
            </span>
        ));
        return (
            <>
                {this.getlist()}
                <MDBContainer fluid className="mx-0 px-0 mt-0" >
                    {this.state.load ? "" :
                        <MDBRow className=" border-top border-bottom border-info py-1 my-2">
                            <div className="marquee">
                                {listItems}
                            </div>
                        </MDBRow>
                    }
                </MDBContainer>
            </>

        );
    }
}
export default AnnouncementList;