import React from 'react';
import Event from './Event';
const RecurringEvents = (props) => {

    const activities = props.activitydata.activities
    const eventsList = activities && activities.map(node => (
        <Event key={node.id} special="false" node={{ ...node }} fullpage={props.activitydata.fullpage}>
        </Event>
    ));
    return (
        <>
            {eventsList}
        </>
    );
};

export default RecurringEvents;