import React, { Component } from 'react';
import { MDBContainer, MDBCol } from 'mdbreact';
import { FirebaseContext } from './Firebase';

class Livestream extends Component {

    static contextType = FirebaseContext

    state = {
        load: true,
        data: {
            id: "",
            title: "",
            url: ""
        }
    }

    getLiveUrl = () => {
        const { firebase, loading } = this.context;
        if (!loading) {
            if (this.state.load) {
                firebase.db.collection("livestream").get().then(querySnapshot => {
                    //console.log(querySnapshot.docs);
                    //debugger;
                    const data = querySnapshot.docs.map(doc => {
                        const datadoc = { id: doc.id, ...doc.data() };
                        return datadoc;
                    });
                    //debugger;
                    this.setState({ load: !this.state.load, data: { id: data[0].id, title: data[0].title, url: data[0].url } });

                }).catch((e) => {
                    console.log(e);
                });
            }
        }
    }
    render() {
        return (
            <div>
                {this.getLiveUrl()}
                <MDBContainer className="mx-0 px-0">

                    <MDBCol md="12" className="mx-0 px-2">
                        <h5 className="px-0 py-2 font-weight-bold ">{this.state.data.title === "" ? "Live" : this.state.data.title} </h5>
                        <iframe width="100%" height="250" src={this.state.data.url === "" ? "https://www.youtube.com/embed/videoseries?list=PLHEtpZYVR4T_0KIyQo03AjP3_lQ8ZFcJQ" : this.state.data.url} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </MDBCol>
                </MDBContainer>

            </div>
        );
    }
}

export default Livestream;
