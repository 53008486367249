import React from "react";
import Event from './Event';


const UpcomingEvents = (props) => {


    const data = props.eventdata.events
    //console.log(data);
    const eventsList = data && data.map(node => (
        <Event key={node.id} special="true" node={{ ...node }} fullpage={props.eventdata.fullpage}>
        </Event>
    ));


    return (
        <>

            {eventsList}

        </>
    )
}

export default UpcomingEvents;